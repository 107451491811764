// Rules to apply to all elements
html * {
    // -webkit-appearance: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-repeat: no-repeat;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
}

html {
    -webkit-font-smoothing: antialiased;
}

// Remove the annoying blue focus that seems to happen in Chrome
:focus {
    outline-color: transparent;
    outline-style: none;
}

body, html {
    width: 100%;
    min-height: 100%;
}

body {
    background-color: $color__white;
    @include font_size_s;
    color: $color__dark_grey;
    font-family: $font__univers;
}

// Smooth CSS transitions for buttons and links
a,
button {
    transition-property: opacity, color, background-color, fill, stroke, border;
    transition-duration: .25s;
}

a {
    color: $color__dark_blue;
    text-decoration: underline;
    &:hover {
        color: $color__black;
    }
}

p {
    margin: 0 0 24px;
}

b,
strong,
.bold {
    font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
}

h1 {
    @include font_size_xl;
    margin: 0 0 32px;
    font-family: $font__sanchez;
    font-weight: 600;
}

h2 {
    @include font_size_l;
    margin: 0 0 32px;
    font-family: $font__sanchez;
    font-weight: 700;
}

h6 {
    margin: 0 0 24px;
    font-weight: 700;
}

hr {
    border: 0 none;
    border-top: 1px solid $color__dark_green;
    margin: 24px 0;
    @include breakpoint(medium){
        margin: 32px 0;
    }
    &.grey {
        border-color: $color__dark_grey;
    }
}

.small {
    @include font_size_xs;
}

.grecaptcha-badge {
    bottom: 70px !important;
}
