.course_thumbs {
	.course_thumb {
		display: block;
		margin: 0 0 40px;
		text-decoration: none;
		.inner {
			display: block;
			padding: 16px 24px;
			.title {
				font-weight: 600;
				display: block;
				text-decoration: underline;
				margin: 0 0 8px;
			}
		}
	}
}

#courses {
	background-color: $color__light_blue;
	padding: 48px 0 24px;
	margin: 48px 0 0;
	.course_thumbs {
		margin: 32px 0 0;
		.course_thumb {
			.inner {
				background-color: $color__white;
				min-height: 300px;
				.course_meta {
					display: block;
					color: $color__dark_grey;
					@include font_size_xs;
					margin: 0 0 8px;
					strong {
						color: $color__dark_green;
					}
				}
			}
		}
	}
}

#other_courses {
	margin: 48px 0 0;
	h2 {
		color: $color__dark_green;
	}
	.course_thumbs {
		margin: 16px 0 0;
		.course_thumb {
			.inner {
				background-color: $color__light_blue;
			}
		}
	}
}

body.course_page {
	#course_meta {
		margin: 0 0 32px;
		.course_meta {
			border-top: 1px solid $color__mid_green;
			border-bottom: 1px solid $color__mid_green;
			margin: 0 0 -1px;
			padding: 16px 0 16px;
			h3 {
				color: $color__dark_green;
				font-weight: 600;
			}
			ul {
				margin: 0;
			}
			&.course_outcomes {
				padding: 16px 0 8px;
			}
			&.course_length,
			&.course_included_in_fee {
				@include font_size_xs;
				padding: 8px 0 16px;
				h3 {
					margin: 0;
				}
			}
		}
	}
}

#course_items {
	background-color: $color__light_blue;
	padding: 48px 0;
	margin: 48px 0 0;
	.course {
		background-color: $color__white;
		padding: 48px;
		margin: 0 0 48px;
		h2 {
			@include font_size_m;
			color: $color__dark_green;
			margin: 0 0 4px;
		}
		.course_address {
			@include font_size_xs;
			color: $color__dark_green;
			font-weight: 600;
		}
		.places_remaining {
			@include font_size_xs;
			color: $color__red;
		}
		.timetable {
			border-top: 1px solid $color__mid_green;
			@include font_size_xs;
			padding: 0;
			margin: 32px 0 16px;
			h3 {
				font-weight: 600;
				color: $color__dark_green;
				margin: 16px 0 0 0;
			}
			table {
				width: 100%;
				border-collapse: collapse;
				border-spacing: 0;
            margin-bottom: 16px;
			}
			td {
				vertical-align: top;
				padding: 0 8px 0 0;
				white-space: nowrap;
				&:first-of-type {
					font-weight: 600;
				}
				&:last-of-type {
					padding-right: 0;
					width: 100%;
				}
			}
			th {
				font-weight: 600;
				border-top: 1px solid $color__dark_green;
			}
         hr {
            border-color: $color__mid_green;
            margin: 0;
         }
		}
		.course_booking_form {
			h3 {
				font-weight: 600;
			}
			&.booking_not_allowed {
				h3 {
					color: $color__red;
				}
			}
			form {
				.course_variant {
					display: flex;
					.variant_details {
						flex: 1;
						padding: 10px 0 0 24px;
						@include font_size_xs;
					}
				}
				[type=submit] {
					margin: 16px 0 0;
				}
			}
		}
	}
}

#course_filters {
	.course_filter {
		margin: 0 0 -1px;
		.course_filter_inner {
			height: 100%;
			border-top: 1px solid $color__white;
			border-bottom: 1px solid $color__white;
			.course_filter_title {
				padding: 8px 0;
				font-family: $font__sanchez;
				font-weight: 700;
				color: $color__dark_blue;
				background-image: url($images_dir + 'caret_down_blue.svg');
				background-position: right 0 top 50%;
				&:hover {
					cursor: pointer;
					color: $color__black;
				}
			}
			.course_filter_options {
				display: none;
			}
		}
		&.open {
			.course_filter_inner {
				.course_filter_title {
					background-image: url($images_dir + 'caret_up_blue.svg');
					color: $color__black;
				}
				.course_filter_options {
					display: block;
				}
			}
		}
	}
	@include breakpoint(large){
		display: flex;
		flex-wrap: wrap;
		margin: 0 -8px;
		.course_filter {
			width: 33.333%;
			padding: 0 8px;
		}
	}
}

.course_info_btn {
	display: block;
	background-image: url($images_dir + 'info_icon.svg');
	padding: 2px 0 0 29px;
	@include font_size_xs;
	margin: 16px 0 16px;
	&:hover {
		cursor: pointer;
	}
}
