.beacon-courses {
  .accordion {
    background-color: $color__white;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 14px;
    }

    .accordion_heading {
      padding: 12px 28px 12px 16px;
      color: $color__dark_blue;
      text-decoration: none;
      position: relative;

      h3 {
        @include font_size_m;
        color: $color__dark_green;
				font-weight: 600;
        margin-bottom: 0;
      }

      .description {
        @include font_size_xs;
        margin-top: 8px;
      }

      .button {
        margin-top: 12px;
        margin-bottom: 12px;
      }

      &:hover {
        color: black;
        cursor: pointer;
        background-color: #fff;

        h3 {
          color: black;
        }
      }

      &::after {
        content: " ";
        background-image: url($images_dir + 'get_gardening/triangle_down.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 16px;
        height: 12px;
        position: absolute;
        top: 24px;
        right: 12px;
        transition: .25s transform;
      }
    }

    .accordion_contents {
      display: none;
      padding: 12px;
    }

    &.open {
      .accordion_heading {
        &::after {
          top: 22px;
          transform: rotate(180deg);
        }
      }

      .accordion_contents {
        height: auto;
      }
    }

    @include breakpoint(large) {
      .accordion_heading {
        padding: 20px 36px 20px 24px;

        .button {
          margin-top: 20px;
        }
        
        &::after {
          top: 32px;
          right: 20px;
        }
      }

      .accordion.open {
        .accordion_heading {
          &::after {
            top: 30px;
          }
        }
      }
    }
  }

  .course-details {
    color: $color__dark_grey;
    border-top: 1px solid $color__mid_green;
    border-bottom: 1px solid $color__mid_green;
    margin: 24px 0;
    padding: 24px 0;

    strong {
      color: $color__dark_green;
    }

    .course-price {

      .price {
        margin-bottom: 0;
        font-weight: inherit;
      }

      .extra-info {
        color: $color__mid_grey;
        @include font_size_xs;
      }
    }

    .extra-course-details {
      @include font_size_xs;
      border-top: 1px solid $color__light_green;
      margin-top: 12px;
      padding-top: 12px;
    }
  }
}
