#donate_widget {
	margin: -220px 0 32px;
	@include breakpoint(medium){
		margin: -190px 0 32px;
	}
	@include breakpoint(large){
		margin: -320px 0 32px;
	}
	.donate_box {
		background-color: $color__white;
		padding: 24px 24px 0;
		@include breakpoint(medium){
			padding: 48px 48px 0;
		}
		.donation_type,
		.donation_amounts {
			display: flex;
			margin: 0 1px 0 0;
			label {
				flex-basis: 0;
				flex-grow: 1;
				margin: 0 0 24px;
				display: block;
				height: 48px;
				text-align: center;
				color: $color__dark_blue;
				input {
					position: absolute;
					opacity: 0;
				}
				span {
					background-color: $color__white;
					height: 48px;
					display: block;
					border: 1px solid $color__orange;
					border-left-width: 0;
					padding: 8px 0 0;
					margin: 0 -1px 0 0;
					font-weight: 400;
					transition: .25s background-color, .25s color;
				}
				input:checked + span {
					border-color: $color__orange;
					background-color: $color__orange;
				}
				&:first-of-type span {
					border-left-width: 1px;
					border-top-left-radius: 3px;
					border-bottom-left-radius: 3px;
				}
				&:last-of-type span {
					border-top-right-radius: 3px;
					border-bottom-right-radius: 3px;
				}
				&:hover {
					cursor: pointer;
					span {
						color: $color__black;
						background-color: lighten($color__orange, 90%);
					}
				}
			}
		}
		.donation_type {
			label {
				position: relative;
				&:before {
					content: "";
					position: absolute;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 20px 20px 0;
					border-color: transparent;
					left: 50%;
					margin-left: -20px;
					bottom: -20px;
					transition: .25s border-color;
				}
				&.active {
					&:before {
						border-color: $color__orange transparent transparent;
					}
				}
			}
		}
		.donation_amounts {
			display: none;
			&.visible {
				display: flex;
			}
			label {
				span {
					border-bottom-left-radius: 0 !important;
					border-bottom-right-radius: 0 !important;
				}
			}
		}
		.donation_amount_benefits {
			.donation_amount_benefit {
				margin: -24px 0 0;
				border-top: 16px solid $color__orange;
				padding: 32px 0 0;
				display: none;
				&.visible {
					display: block;
				}
				img {
					margin: 16px 0 0;
				}
			}
		}
		.field {
			margin: 24px 0;
		}
		input#donation_other_amount_field {
			width: 240px;
		}
	}
}

// Donation form

#donate_form {
	margin: 0 0 48px;
}

.donation_details {
	span {
		margin: 0 24px 0 0;
	}
	#donation_amount_field {
		width: 100px;
		display: inline-block;
		vertical-align: bottom;
		margin: 0 0 0 8px;
		font-size: inherit;
		line-height: 1em;
		padding: 8px 8px 0;
		height: auto;
		font-weight: 700;
	}
}

#gift_aid {
	margin: 32px 0 32px;
	#gift_aid_logo {
		display: inline-block;
		width: 192px;
		height: 68px;
		background-image: url($images_dir + 'logos/gift_aid.svg');
		opacity: 0.7;
		margin: 0 0 16px;
	}
	.input_checkbox {
		margin-bottom: 18px;
	}
	p.first {
		margin-bottom: 12px;
	}
	p.small {
		line-height: 28px;
		margin: 0 0 8px;
	}
	body.shop & {
		hr {
			display: none;
		}
	}
}

#first_name_field,
#last_name_field,
#city_field,
#billing_town_field {
	@include breakpoint(medium){
		width: 288px;
	}
}
#first_name_field,
#city_field,
#billing_town_field {
	@include breakpoint(medium){
		margin: 0 48px 8px 0;
	}
}
#postcode_field,
#billing_postcode_field {
	@include breakpoint(medium){
		width: 144px;
	}
}

#different_billing_address {
	margin: 16px 0 0;
}

#payment_method_stripe {
	label {
		padding-right: 132px;
		display: inline-block;
		background-image: url($images_dir + 'logos/creditcards.png');
		background-size: 122px 22px;
		background-position: top 4px right 0;
	}
}
#payment_method_paypal {
	label {
		padding-right: 93px;
		display: inline-block;
		background-image: url($images_dir + 'logos/paypal.png');
		background-size: 83px 22px;
		background-position: top 4px right 0;
	}
}
#payment_method_apple_pay {
	label {
		padding-right: 63px;
		display: inline-block;
		background-image: url($images_dir + 'logos/apple_pay.png');
		background-size: 53px 22px;
		background-position: top 4px right 0;
	}
}

#donation_details_repeat {
	h2 {
		margin: 0 0 8px;
	}
}

.donate_info_block {
	border-top: 1px solid $color__mid_green;
	padding: 24px 0;
	position: relative;
	&:last-of-type {
		border-bottom: 1px solid $color__mid_green;
	}
	h2 {
		color: $color__dark_green;
		margin: 0 0 8px;
	}
	p {
		margin: 0;
		max-width: 612px;
	}
	&.with_image {
		display: flex;
		.details {
			flex: 1;
		}
		.image {

		}
		#payment_logos {
			background-image: url($images_dir + 'logos/payment_logos.png');
			background-size: contain;
			width: 190px;
			height: 198px;
		}
	}

}
