body.get_gardening_home #page {
	background-color: #FCF9F6;
}

#get_gardening_category_filter {
	background-color: $color__light_green;
	padding: 24px 0 32px;
	position: relative;
	margin: 0 0 56px;

	h2 {
		@include font_size_ml;
		font-family: $font__univers;
		font-weight: 400;
		margin: 0 0 16px;
	}

	h3 {
		margin: 0 0 16px;
	}

	#current_category_filter_wrapper {
		outline: 1px solid white;
		padding: 23px 72px;
		text-align: center;
		background-image: url($images_dir + 'get_gardening/quote_left.png'), url($images_dir + 'get_gardening/quote_right.png');
		background-size: 36px 24px;
		background-position: left 24px top 24px, right 24px top 32px;
		display: flex;

		#current_category_filter_label {
			display: inline-block;
			font-family: $font__sanchez;
			font-weight: 700;
			font-size: 36px;
			line-height: 40px;
			margin-right: 8px;
			padding-top: 6px;
		}
		
		#current_category_filter {
			min-width: 285px;
			margin: 0 0 0 8px;
			display: inline-block;
			height: 48px;
			font-size: 24px;
			line-height: 24px;
			border: 1px solid $color__dark_blue;
			box-shadow: 0px 4px 0px #87827E;
			border-radius: 3px;
			background-color: #FCF9F6;
			background-image: url($images_dir + 'get_gardening/triangle_down.svg');
			background-size: 13px 10px;
			background-position: right 14px top 50%;
			padding: 12px 38px 12px 12px;
			transition: .25s background-color;
			text-align: left;

			&:hover {
				cursor: pointer;
				background-color: mix(black, #FCF9F6, 10%);
			}
		}

		@include breakpoint(medium down) {
			display: block;
			background-position: right calc(50% + 110px) top 32px, left calc(50% + 110px) top 32px;
			padding: 23px 32px;

			#current_category_filter_label {
				display: block;
				margin-bottom: 16px;
				margin-right: 0;
			}
			
			#current_category_filter {
				margin: 0;
				display: block;
				height: auto;
				line-height: 32px;
			}
		}
	}

	.rough_strip_bottom {
		position: absolute;
		bottom: -16px;
		height: 16px;
		width: 100%;
		background-size: 1024px 16px;
		background-repeat: repeat-x;
		background-position: center;
		background-image: url($images_dir + 'header_illustrations/rough_strip_light_green.png');
	}
}

#get_gardening_category_filters {
	z-index: 10000;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.overlay {
		position: fixed;
		background-color: rgba(0,0,0,0.4);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.options {
		background-color: white;
		position: absolute;

		.option {
			padding: 4px 12px;
			@include font_size_m;
			color: $color__dark_blue;
			transition: 0.25s background-color, 0.25s color;

			&:hover {
				cursor: pointer;
				background-color: rgba($color__dark_blue, 0.25);
			}

			&.selected {
				color: white;
				background-color: $color__dark_blue;
			}
		}
	}
}

#get_gardening_tag_filters {
	// TODO - this might be best merged with .modal_wrapper etc styles
	z-index: 10000;
	background-color: rgba(0,0,0,0.4);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	.inner {
		background-color: white;
		width: 1084px;
		padding: 24px 26px;
		position: relative;
		margin: 0 16px;
		
		h4 {
			@include font_size_m;
			font-weight: 600;
			margin: 0 0 32px;
		}

		.close_btn {
			color: $color__dark_blue;
			text-decoration: underline;
			position: absolute;
			top: 24px;
			right: 24px;
			&:hover {
				cursor: pointer;
				color: $color__black;
			}
		}

		hr {
			border-color: #555555;
			margin: 24px 0;
		}
	}

	@include breakpoint(medium down) {
		display: block;
		width: 100%;
		overflow: auto;

		.inner {
			width: auto;
			margin: 12px;
		}
	}
}

.get_gardening_tags {
	h5 {
		@include font_size_xs;
		font-weight: 400;
		margin: 18px 0 12px;
	}
}

.gardening_topics {
	color: $color__dark_green;
}

.health_and_wellbeing {
	color: $color__terracotta;
}

.tags {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;

	.tag {
		border: 1px solid;
		padding: 9px 12px 7px;
		border-radius: 3px;
		font-size: 18px;
		line-height: 18px;
		font-weight: 400;
		&.tag_btn {
			transition: 0.25s color, 0.25s background-color;
			&:hover {
				cursor: pointer;
			}
		}

		&.gardening_topics {
			border-color: $color__dark_green;
		}

		&.health_and_wellbeing {
			border-color: $color__terracotta;
		}
	}

	.tag_btn,
	a.tag {
		text-decoration: none;

		&.gardening_topics {
			color: $color__dark_green;
			&:hover {
				background-color: rgba($color__dark_green, 0.25);
			}
			&.selected {
				color: white;
				background-color: $color__dark_green;
			}
		}

		&.health_and_wellbeing {
			color: $color__terracotta;
			&:hover {
				background-color: rgba($color__terracotta, 0.25);
			}
			&.selected {
				color: white;
				background-color: $color__terracotta;
			}
		}
	}
}

#get_gardening_content {
	margin: 0 auto;
	max-width: 1035px;

	.get_gardening_category_summary {
		margin: 0 0 48px;
		h2 {
			padding: 0 0 12px;
			margin: 0 0 48px;
			color: $color__dark_green;
			border-bottom: 1px solid $color__mid_green;
		}
		.description {
			@include font_size_m;
		}

		@include breakpoint(large down) {
			margin-left: 12px;
			margin-right: 12px;
		}
	}

	#get_gardening_selected_tag_filters {
		z-index: 1;
		position: relative;
		border: 1px solid $color__dark_green;
		padding: 56px 170px 24px 16px;
		margin: 64px 0 42px;

		&.no_active_tag_filters {
			border-left: 0 none;
			border-right: 0 none;
			border-bottom: 0 none;
			padding-bottom: 0;

			#get_gardening_clear_filters {
				display: none;
			}
		}
		
		.show_tag_filters_btn_wrapper {
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			justify-content: center;
			width: 100%;
			height: 48px;
			transform: translateY(-50%);

			.whitespace {
				display: block;
				width: 16px;
				height: 40px;
				background-color: #FCF9F6;
			}

			#show_tag_filters_btn {
				background-image: url($images_dir + 'get_gardening/filters.svg');
				background-size: 18px 18px;
				background-position: right 12px top 12px;
				background-repeat: no-repeat;
				@include font_size_xs;
				padding: 9px 40px 9px 12px;
				height: 40px;
			}
		}

		#get_gardening_clear_filters {
			position: absolute;
			@include font_size_xs;
			top: 56px;
			right: 24px;
			cursor: pointer;
			color: $color__dark_blue;
			text-decoration: underline;
			&:hover {
				color: black;
			}
		}

		.tags {
			gap: 14px 36px;
	
			.tag {
				padding: 4px 6px;
				font-size: 16px;
				position: relative;
		
				&:last-of-type {
					&::after {
						content: none;
						display: none;
					}
				}
		
				&::after {
					content: "+";
					color: black;
					font-size: 20px;
					line-height: 20px;
					position: absolute;
					width: 12px;
					text-align: center;
					top: 2px;
					right: -24px;
				}
		
				&.gardening_topics {
					color: white;
					background-color: $color__dark_green;
					
					&:hover {
						background-color: mix(white, $color__dark_green, 40%);
						border-color: mix(white, $color__dark_green, 40%);
					}
				}
		
				&.health_and_wellbeing {
					color: white;
					background-color: $color__terracotta;
					
					&:hover {
						background-color: mix(white, $color__terracotta, 40%);
						border-color: mix(white, $color__terracotta, 40%);
					}
				}
			}
		}

		@include breakpoint(large down) {
			margin-left: 12px;
			margin-right: 12px;
		}
	}

	.no_articles {
		text-align: center;
		color: $color__dark_blue;
		.title {
			@include font_size_ml;
			margin: 0 0 12px;
		}
	}
	
	#get_gardening_entries {
		display: flex;
		flex-wrap: wrap;
		gap: 45px;

		@include breakpoint(large down) {
			margin: 0 12px;
			gap: 24px;

			.get_gardening_thumb {
				flex-basis: calc(50% - 24px / 2);
			}
		}

		@include breakpoint(small down) {
			.get_gardening_thumb {
				flex-basis: 100%;
			}
		}
	}
}

.get_gardening_thumb {
	flex-basis: calc(50% - 45px / 2);
	background-color: white;
	
	.inner {
		padding: 16px 24px;
		
		.title {
			@include font_size_m;
			font-weight: 600;
			margin: 0 0 16px;
		}

		.excerpt {
			@include font_size_xs_alt;
			margin-bottom: 16px;
		}

		.get_gardening_tags {
			h5 {
				font-size: 16px;
				margin: 12px 0 6px;
			}
			.tag {
				padding: 4px 6px;
				font-size: 16px;
			}
		}
	}

	&.featured {
		display: flex;

		.image {
			display: block;
			width: 48%;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.inner {
			width: 52%;
			padding: 16px 24px 24px 45px;
		}

		@include breakpoint(small down) {
			display: block;

			.image {
				width: auto;
			}

			.inner {
				width: auto;
				padding: 16px 24px;
			}
		}
	}
}

body.section_get_gardening,
body.section_get_gardening_home {
	// TODO - refactor and unify this with .rough_block styles
	.gg_rough_block {
		padding: 24px 36px;
		position: relative;
		// background-color: $color__{COLOR};

		@include breakpoint(medium down) {
			padding: 12px 18px;
		}

		&::before,
		&::after {
			content: " ";
			display: block;
			width: 100%;
			height: 16px;
			position: absolute;
			left: 0;
			// background-image: url($images_dir + 'header_illustrations/rough_strip_{COLOR}.png');
			background-size: 1024px 16px;
			background-position: left 0 top 0;
			background-repeat: repeat-x;
		}

		&::before {
			top: -16px;
			transform: rotate(180deg);
		}

		&::after {
			bottom: -16px;
		}

		&.icon_block {
			display: flex;

			.icon {
				background-size: 66px 64px;
				background-repeat: no-repeat;
				background-position: top 0 left 50%;
				margin: 0 20px 0 0;
				width: 66px;
				flex: 0 0 auto;

				@include breakpoint(medium down) {
					width: 40px;
					background-size: 40px auto;
				}
			}

			.content {
				flex: 0 1 auto;
			}
		}
	}

	#page {
		#anchors {
			border-bottom: 0 none;
			position: absolute;
			max-width: 160px;
			&.sticky {
				top: 16px;
				position: fixed;

				@include breakpoint(large down){
					position: static;
				}
			}

			@include breakpoint(large down){
				max-width: none;
				position: static;
			}

			.sub_heading_nav_link {
				font-size: 16px;
				line-height: 24px;
				margin: 0 0 10px;

				a {
					text-decoration: none;

					span {
						text-decoration: underline;
					}

					&:hover {
						font-weight: bold;
					}
				}

				&.current a {
					font-weight: bold;
				}
			}
		}

		.share_btns {
			display: flex;
			gap: 22px;
			
			@include breakpoint(large down) {
				margin-top: 24px;
			}

			.share_btn {
				width: 34px;
				height: 34px;
				display: inline-block;
				background-position: center;
	
				&.share_btn_email {
					background-image: url($images_dir + 'get_gardening/share/email.svg');
					background-size: 34px 28px;
				}
				
				&.share_btn_whatsapp {
					background-image: url($images_dir + 'get_gardening/share/whatsapp.svg');
					background-size: 34px 34px;
				}
				
				&.share_btn_print {
					background-image: url($images_dir + 'get_gardening/share/print.svg');
					background-size: 35px 34px;
				}
			}
		}

		.block {
			&_anchor {
				h2 {
					@include font_size_l;
					margin: 24px 0 24px;
					border-top: 1px solid $color__mid_green;
					
					a {
						display: inline-block;
						padding-top: 32px;
					}
				}
			}

			&_gardening_tip {
				margin: 40px 0 40px;
				.gg_rough_block {
					background-color: $color__light_green;

					&::before,
					&::after {
						background-image: url($images_dir + 'header_illustrations/rough_strip_light_green.png');
					}

					.icon {
						background-image: url($images_dir + 'get_gardening/gardening_tip.png');
					}

					.content {
						h3 {
							@include font_size_l;
							font-weight: 600;
							margin: 0 0 24px;
						}
					}
				}
			}

			&_make_it_easier {
				margin: 40px 0 40px;
				.gg_rough_block {
					background-color: $color__teal;

					&::before,
					&::after {
						background-image: url($images_dir + 'header_illustrations/rough_strip_teal.png');
					}

					.icon {
						background-image: url($images_dir + 'get_gardening/trowel.png');
					}

					.content {
						h3 {
							@include font_size_l;
							font-weight: 600;
							margin: 0 0 24px;
						}

						.accordions {
							border-bottom: 1px solid $color__dark_blue;
							margin-bottom: 6px;
							.accordion {
								border-top: 1px solid $color__dark_blue;
								.accordion_heading {
									padding: 12px 0;
									color: $color__dark_blue;
									text-decoration: underline;
									position: relative;

									&:hover {
										color: black;
										cursor: pointer;
									}

									&::after {
										content: " ";
										background-image: url($images_dir + 'get_gardening/triangle_down.svg');
										background-repeat: no-repeat;
										width: 16px;
										height: 12px;
										position: absolute;
										top: calc(50% - 6px);
										right: 0;
										transition: .25s transform;
									}
								}

								.accordion_contents {
									display: none;
									padding: 0 0 16px;
								}

								&.open {
									.accordion_heading {
										&::after {
											transform: rotate(180deg);
										}
									}

									.accordion_contents {
										height: auto;
									}
								}
							}
						}

						ul {
							li {
								&:before {
									background-image: url($images_dir + 'get_gardening/bullet.png');
								}
							}
						}
					}
				}
			}

			&_small_donation_widget {
				margin: 72px 0 72px;
				.gg_rough_block {
					background-color: $color__yellow;

					&::before,
					&::after {
						background-image: url($images_dir + 'header_illustrations/rough_strip_yellow.png');
					}

					.icon {
						background-image: url($images_dir + 'icons/flower_in_hand.png');
						background-size: contain;
						width: 120px;
						height: 169px;
						margin: 16px 30px 0 0;

						@include breakpoint(medium down) {
							width: 40px;
							height: 56px;
							margin: 12px 24px 0 0;
						}
					}

					.content {
						h3 {
							@include font_size_ml;
							margin: 0 0 24px;
						}

						.button {
							box-shadow: 0 4px 0 #555555;
						}
					}
				}
			}
		}

		.credit {
			background-color: white;
			display: flex;
			align-items: center;
			padding: 16px;
			margin: 0 0 16px;
			
			.logo {
				width: 128px;
				height: 128px;
				margin-right: 16px;
				display: flex;
				align-items: center;
			}
		}
	}
}

.get_gardening_related_content {
	margin: 0 0 94px;

	h2 {
		font-family: $font__univers;
		font-weight: 400;
		border-bottom: 1px solid $color__mid_green;
		margin: 0 0 48px;
		padding: 0 0 16px;
	}
}

#enews_signup_form {
	margin: 72px 0 94px;

	.gg_rough_block {
		color: white;
		background-color: $color__dark_blue;

		&::before,
		&::after {
			background-image: url($images_dir + 'header_illustrations/rough_strip_dark_blue.png');
		}

		.icon {
			background-image: url($images_dir + 'icons/envelope.png');
			background-size: contain;
			width: 160px;
			height: 122px;
			margin: 0 45px 0 0;

			@include breakpoint(medium down) {
				width: 40px;
				height: 30px;
				margin: 0 24px 0 0;
			}
		}

		.content {
			max-width: 640px;

			h2 {
				@include font_size_ml;
				font-family: $font__univers;
				font-weight: 400;
				margin: 0 0 16px;
			}

			h3 {
				@include font_size_s;
				margin: 0 0 24px;
			}

			label.input_checkbox {
				margin: 0 0 16px;
				padding: 0 0 0 48px;
				input {
					&:checked + .checkbox {
						background-size: 24px auto;
					}
				}
				.checkbox {
					top: 2px;
					width: 32px;
					height: 32px;
					border: 1px solid white;
					background-color: transparent;
				}
			}

			.field_group {
				margin: 32px 0;
			}

			.button {
				box-shadow: 0 4px 0 #555555;
			}
		}
	}

	#enews_signup_form_hidden_fields {
		display: none;

		.field_group:first-of-type {
			margin-top: 0;
			padding-top: 24px;
		}
	}
}

#get_gardening_load_more {
	margin: 48px 0 0;
}

body.get_gardening_home {
	.block_nav_boxes_with_images {
		@include breakpoint (medium down){
			padding-left: 12px;
			padding-right: 12px;
		}
	}
}

@media print {
	body.section_get_gardening {
		#header_top_mobile,
		#header_top_mobile *,
		#header_top,
		#header_top *,
		#header_navigation_wrapper,
		#header_navigation_wrapper *,
		#breadcrumbs,
		#search_bar,
		#donate_btn_wrapper,
		#anchors,
		.gg_rough_block::after,
		.gg_rough_block::before,
		.block_small_donation_widget,
		#enews_signup_form,
		#hero_image,
		.illustrations,
		#footer {
			display: none !important;
			padding: 0 !important;
			margin: 0 !important;
			height: 0 !important;
			min-height: 0 !important;
		}
	
		body {
			font-size: 16px;
			line-height: 30px;
		}
		
		#page_title {
			font-size: 30px;
			line-height: 40px;
			text-align: left !important;
		}

		hr {
			display: none;
		}
		
		h2,
		h3,
		#standfirst {
			font-size: 22px !important;
			line-height: 32px !important;
		}
		
		#page_header {
			height: auto !important;
			min-height: 0 !important;
			padding: 0 !important;
	
			#page_header_inner {
				padding: 0 !important;
			}
		}
	
		.icon_block {
			padding: 0 !important;
		}
	
		.tags {
			margin: 0 24px;
		}
	
		.icon_block {
			.icon {
				display: none;
			}
		}

		.accordion {
			border: 0 none !important;
			
			.accordion_heading {
				&::after {
					display: none;
				}
			}

			.accordion_contents {
				display: block !important;
			}
		}
	
		.cell {
			display: block !important;
			width: 100% !important;
			margin: 0 !important;
		}
	
		.block_make_it_easier,
		.block_gardening_tip {
			margin: 24px 0 !important;
		}
	}
}