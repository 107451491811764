#page {
    position: relative;
    #hero_image {
        margin: -220px 0 32px;

        @include breakpoint(medium){
            margin: -190px 0 32px;
        }
        
        @include breakpoint(large){
            margin: -320px 0 32px;
        }
    }
    #standfirst {
        margin: 0 0 24px;
        @include font_size_ml;
    }
    #anchors {
        padding: 32px 0 24px;
        margin: 0 0 32px;
        border-top: 1px solid $color__mid_green;
        border-bottom: 1px solid $color__mid_green;
        h5 {
            font-weight: 600;
        }
        h6 {
            font-size: 16px;
            line-height: 24px;
            @include breakpoint(medium){
                font-size: 18px;
                line-height: 28px;
            }
            margin: 0 0 8px;
            font-weight: 400;
        }
    }
    li {
        margin: 0 0 8px;
    }
    ul {
        margin: 0 0 24px;
        li {
            list-style-type: none;
            padding: 0 0 0 24px;
            position: relative;
            &:before {
                position: absolute;
                left: 0;
                top: 6px;
                content: " ";
                display: block;
                width: 16px;
                height: 16px;
                background-image: url($images_dir + 'bullet.png');
                background-size: contain;
            }
        }
    }
    ol {
        margin: 0 0 24px;
        counter-reset: item;
        li {
            list-style-type: none;
            padding: 0 0 0 24px;
            position: relative;
            &:before {
                position: absolute;
                left: 0;
                display: block;
                content: counter(item);
                counter-increment: item + 1;
                color: $color__dark_green;
                width: 24px;
            }
        }
    }
    // Matrix fields (see /templates/partials/matrix/*)
    .block {
        margin: 0 0 40px;
        &_anchor {
            margin: 0;
            h2 {
                @include font_size_s;
                font-family: $font__univers;
                font-weight: 700;
                margin: 0 0 24px;
                a {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }
        &_rich_text {
            margin: 0 0 8px;
            p {
                margin: 0 0 24px;
            }
            h2 {
                @include font_size_s;
                font-family: $font__univers;
                font-weight: 700;
                margin: 0 0 24px;
            }
        }
        &_image,
        &_imagePortrait,
        &_video {
            margin: 0 0 24px;
            .caption {
                margin: 18px 0 0;
                text-align: center;
                color: $color__dark_green;
                @include font_size_xs;
            }
        }
        &_imagePortrait {
            .image-portrait {
                width: 480px;
            }
            .caption {
                width: 480px;
                max-width: 100%;
            }
        }
        &_pull_quote {
            blockquote {
                border: 0;
                margin: 0;
                padding: 0;
                .blockquote_text {
                    color: $color__dark_green;
                    @include font_size_m;
                    margin: 0 0 4px;
                    padding: 0;
                }
                cite {
                    color: $color__dark_green;
                    @include font_size_xs;
                    font-style: normal;
                }
            }
            .left_quotes {
                vertical-align: top;
                display: inline-block;
                width: 48px;
                height: 28px;
                background-image: url($images_dir + 'quote_left.png');
                background-size: auto 24px;
                background-position: left 0 bottom 0;
            }
            .right_quotes {
                vertical-align: top;
                display: inline-block;
                width: 48px;
                height: 29px;
                background-image: url($images_dir + 'quote_right.png');
                background-size: auto 24px;
                background-position: right 0 bottom 0;
            }
        }
        &_link_with_icon {
            .link_with_icon {
                border-top: 1px solid $color__mid_green;
                border-bottom: 1px solid $color__mid_green;
                padding: 24px 0;
                display: flex;
                .icon {
                    flex: 0 0 112px;
                    margin: 0 24px 0 0;
                }
                .link_with_icon_inner {
                    padding: 8px 0 0;
                    h3 {
                        font-family: $font__sanchez;
                        @include font_size_m;
                        font-weight: 700;
                        color: $color__terracotta;
                        margin: 0 0 8px;
                    }
                    a {
                        font-weight: 700;
                    }
                }
            }
        }
        &_table_vertical,
        &_table_horizontal {
            .table_wrapper {
                border-top: 1px solid $color__dark_green;
                border-bottom: 1px solid $color__mid_green;
                @include font_size_xs;
                h6 {
                    color: $color__dark_green;
                    font-weight: 600;
                    margin: 8px 0 12px;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                    border-spacing: 0;
                }
            }
        }
        &_table_vertical {
            td,
            th {
                border-top: 1px solid $color__mid_green;
                vertical-align: top;
                padding: 8px 24px 12px 0;
                white-space: nowrap;
                &:last-of-type {
                    padding-right: 0;
                    width: 100%;
                }
            }
            th {
                font-weight: 600;
                border-top: 1px solid $color__dark_green;
            }
        }
        &_table_horizontal {
            td {
                border-top: 1px solid $color__mid_green;
                vertical-align: top;
                padding: 8px 24px 12px 0;
                white-space: nowrap;
                &.header {
                    font-weight: 600;
                }
                &.content {
                    padding-right: 0;
                    width: 100%;
                }
            }
        }
        &_related_entry {
            background-color: $color__light_blue;
            padding: 24px 0;
            @include breakpoint(medium){
                padding: 48px 0;
            }
            h2 {
                font-family: $font__sanchez;
                @include font_size_l;
                font-weight: 700;
                color: $color__dark_blue;
                margin: 0 0 8px;
            }
            p {
                @include font_size_xs;
                margin: 0 0 8px;
            }
            a {
                font-weight: 600;
            }
        }
        &_cta {
            .cta {
                border-top: 1px solid $color__mid_green;
                border-bottom: 1px solid $color__mid_green;
                display: flex;
                padding: 24px 0 32px;
                margin: 0;
                @include breakpoint(medium){
                    margin: 0 48px;
                }
                &.blue {
                    background-color: $color__dark_blue;
                    color: $color__white;
                    padding: 24px;
                    margin: 0;
                    border: 0 none;
                    @include breakpoint(medium){
                        padding: 48px;
                    }
                    .cta_inner {
                        h2 {
                            color: $color__mid_green;
                        }
                    }
                }

                &.yellow {
                    background-color: $color__yellow;
                    // color: $color__white;
                    padding: 24px;
                    margin: 0;
                    border: 0 none;
                    @include breakpoint(medium){
                        padding: 48px;
                    }
                    .cta_inner {
                        h2 {
                            color: $color__dark_blue;
                        }
                    }
                }

                .icon {
                    flex: 0 0 112px;
                    margin: 0 0 0 48px;
                }
                .cta_inner {
                    flex: 1;
                    padding: 8px 0 0;
                    h2 {
                        color: $color__dark_green;
                        margin: -8px 0 8px;
                    }
                    p {
                        margin: 0;
                    }
                    .button {
                        margin: 24px 0 0;
                        &:active {
                            margin: 28px 0 -4px;
                        }
                    }
                }
                .image {
                    margin: 0 0 0 24px;
                }

                // button text gets partly hidden on mobile, so make the banner as 1 column
                @media print, screen and (max-width: 459px) {
                    flex-direction: column-reverse;

                    .icon {
                        margin: 0 0 16px 0;
                    }

                    .cta_inner {
                        .button {
                            height: auto;
                        }
                    }
                }
            }
        }
        &_nav_boxes {
            a.box {
                .inner {
                    .title {
                        margin: 0 0 8px;
                    }
                }
            }
        }
        &_nav_boxes,
        &_nav_boxes_with_images {
            @include breakpoint (medium down){
                margin-left: -4.25%;
                margin-right: -4.25%;
            }
            @include breakpoint (small down){
                margin-left: 0;
                margin-right: 0;
            }
            h2 {
                color: $color__dark_green;
                border-top: 1px solid $color__mid_green;
                padding: 24px 0 0;
                margin: 0 0 -12px;
            }
            a.box {
                background-color: $color__light_green;
                display: block;
                text-decoration: none;
                margin: 40px 0 0;
                .inner {
                    display: block;
                    padding: 16px 24px 24px;
                    .title {
                        text-decoration: underline;
                        font-weight: 600;
                        display: block;
                    }
                    .text {
                        @include font_size_xs;
                        display: block;
                    }
                }
                &:hover {
                    background-color: darken($color__light_green, 10%);
                }
                &.box_large {
                    .inner {
                        .title {
                            @include font_size_l;
                            font-family: $font__sanchez;
                            font-weight: 700;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        &_stats {
            .stats {
                padding: 24px 0 0;
                border-top: 1px solid $color__mid_green;
                h2 {
                    color: $color__dark_green;
                }
                .stat {
                    margin: 0 0 40px;
                    h3 {
                        @include font_size_xl;
                        font-family: $font__sanchez;
                        color: $color__dark_green;
                        font-weight: 600;
                    }
                    .icon_wrapper {
                        text-align: center;
                        .icon {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
    // Other elements
    // 'Rough block'
    .rough_block {
        position: relative;
        padding: 16px 0;
        margin: 0 0 24px;
        z-index: 2;
        .rough_strip_top,
        .rough_strip_bottom {
            position: absolute;
            height: 16px;
            width: 100%;
            background-size: 1024px 16px;
            background-repeat: repeat-x;
            background-position: center;
        }
        .rough_strip_top {
            top: 0;
            transform: rotate(180deg);
        }
        .rough_strip_bottom {
            bottom: 0;
        }
        .inner {
            padding: 48px 48px 56px;
            @include breakpoint(medium down){
                padding: 16px;
            }
        }
        &.no_bg {
            .inner {
                @include breakpoint(medium down){
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
        &.rough_block_full_width {
            z-index: 1;
            .inner {
                @include breakpoint(medium down){
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
        &.padding_top {
            margin-top: -104px;
            .inner {
                padding-top: 120px;
                @include breakpoint(medium down){
                    padding-top: 90px;
                }
            }
        }
        &.padding_bottom {
            margin-bottom: -72px;
            .inner {
                padding-bottom: 120px;
                @include breakpoint(medium down){
                    padding-bottom: 90px;
                }
            }
        }
        h2 {
            margin: 0 0 8px;
            color: $color__dark_blue;
        }
        &.light_blue {
            .inner {
                background-color: $color__light_blue;
            }
            .rough_strip_top,
            .rough_strip_bottom {
        		background-image: url($images_dir + 'header_illustrations/rough_strip_light_blue.png');
            }
        }
        &.light_green {
            .inner {
                background-color: $color__light_green;
            }
            .rough_strip_top,
            .rough_strip_bottom {
        		background-image: url($images_dir + 'header_illustrations/rough_strip_light_green.png');
            }
        }
        &.mid_green {
            .inner {
                background-color: $color__mid_green;
            }
            .rough_strip_top,
            .rough_strip_bottom {
        		background-image: url($images_dir + 'header_illustrations/rough_strip_mid_green.png');
            }
        }
        &.dark_green {
            .inner {
                background-color: $color__dark_green;
            }
            .rough_strip_top,
            .rough_strip_bottom {
        		background-image: url($images_dir + 'header_illustrations/rough_strip_dark_green.png');
            }
        }
    }
    // Yellow CTA block
    .yellow_cta_block {
        background-color: $color__yellow;
        padding: 24px;
        margin: 32px 0 -220px;
        @include breakpoint(medium){
            padding: 48px 0;
            margin: 64px 0 -180px;
        }
        @include breakpoint(medium){
            margin: 64px 0 -325px;
        }
        h2 {
            margin: 0 0 24px;
        }
        &.yellow_cta_block_multiple {
            padding: 48px 48px 24px;
            h2 {
                margin: 0 0 8px;
            }
            h3 {
                @include font_size_m;
                font-family: $font__sanchez;
                color: $color__dark_blue;
                font-weight: 700;
            }
            p.small {
                margin: 0 0 8px;
            }
            a {
                font-weight: 600;
            }
            .image {
                margin: 0 0 24px;
            }
            @include breakpoint(medium down){
                padding: 24px 24px 1px;
                .yellow_cta_block_item {
                    margin: 0 0 24px;
                }
                .image {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
    		}
        }
    }
}