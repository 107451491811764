footer#footer {
    margin: 64px 0 0;
    &.page_has_yellow_cta_block #footer_inner {
        padding-top: 130px;
        @include breakpoint(small down){
            padding-top: 48px;
        }
    }
    #footer_inner {
        padding: 48px 0 24px;
        color: $color__white;
        background-color: $color__dark_grey;
        h2 {
            color: $color__mid_green;
            margin: 0 0 48px;
            @include font_size_xl;
            font-family: $font__sanchez;
            font-weight: 600;
        }
        #footer_menu,
        #footer_menu_secondary {
            list-style-type: none;
            margin: 0;
            li {
                display: inline-block;
                margin: 0 16px 0 0;
            }
            @include breakpoint(medium down){
                margin: 0 0 -16px;
                li {
                    margin-bottom: 16px;
                }
            }
        }
        #footer_menu {
            a {
                font-weight: 600;
            }
        }
        #fr_logo {
            display: block;
            width: 183px;
            height: 56px;
            background-image: url($images_dir + 'logos/fr_logo.png');
            background-size: contain;
        }
        .narrow {
            max-width: 432px;
        }
        a {
            color: $color__white;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        .small {
            @include breakpoint(medium down){
                margin: 0 0 8px;
            }
        }
        .credit {
            font-size: 14px;
            line-height: 20px;
            margin: 0 0 24px;
        }
    }
}