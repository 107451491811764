header#header {
	#header_top {
		height: 130px;
		#thrive_logo {
			display: block;
			width: 177px;
			height: 88px;
			background-image: url($images_dir + 'logos/thrive_logo.png');
			background-size: contain;
			margin: 16px 0 0;
		}
		#donate_btn_wrapper {
			vertical-align: top;
			display: inline-block;
			padding: 39px 0 0 0;
		}
	}
	#search_bar,
	#search_bar_mobile {
		display: inline-block;
		margin: 44px 30px 0 0;
		width: 240px;
		position: relative;
		input[type=search] {
			margin: 0;
		}
		input[type=submit] {
			background-color: transparent;
			background-image: url($images_dir + 'search.png');
			background-size: 18px;
			background-position: center;
			box-shadow: none;
			position: absolute;
			top: 0;
			right: 0;
			height: 40px;
			padding: 0;
			width: 40px;
		}
	}
	#search_bar_mobile {
		display: block;
		border-top: 1px solid $color__light_blue;
		margin: 0 0 16px;
		padding: 16px 0 0;
		width: auto;
		input[type=submit] {
			top: 16px;
		}
	}
	#header_top_mobile {
		height: 96px;
		background-color: $color__dark_blue;
		#mobile_menu {
			position: relative;
			#thrive_logo_white {
				display: inline-block;
				background-image: url($images_dir + 'logos/thrive_logo_white.png');
				width: 145px;
				height: 72px;
				background-size: contain;
				margin: 8px 0 0;
			}
			#menu_btn {
				display: inline-block;
				background-image: url($images_dir + 'menu_btn.svg');
				width: 29px;
				height: 29px;
				background-size: 29px 29px;
				background-position: center;
				display: inline-block;
				position: absolute;
				top: 32px;
				right: 0;
				&:hover {
					cursor: pointer;
					opacity: 0.8;
				}
				&.menu_open {
					background-image: url($images_dir + 'close_btn.svg');
					background-size: 23px 23px;
				}
			}
		}
	}
	#header_navigation_wrapper {
		background-color: $color__dark_blue;
		min-height: 64px;
		color: $color__white;
		@include breakpoint(large){
			display: block !important;
		}
		#primary_navigation {
			list-style-type: none;
			margin: 0;
			padding: 0;
			ul, li {
				list-style-type: none;
				margin: 0;
				padding: 0;
			}
			> li {
				vertical-align: top;
				display: inline-block;
				padding: 0 44px 0 0;
				margin: 17px 10px 0 0;
				background-image: url($images_dir + 'caret_down_white.svg');
				background-position: right 16px top 10px;
				@include font_size_sm;
				font-family: $font__sanchez;
				font-weight: 700;
				border-right: 1px solid $color__light_blue;
				transition: .25s color;
				&:last-of-type {
					border-right: 0 none;
				}
				.primary_navigation_section {
					z-index: 1000000;
					left: 0;
					width: 100%;
					display: none;
					position: absolute;
					background-color: $color__dark_blue;
					> li {
						border-top: 1px solid $color__light_blue;
						margin: 18px 24px 0;
						padding: 32px 0 16px;
						@include breakpoint(extralarge){
							margin: 14px auto 0;
							max-width: ($global-width * calc(23 / 25)) - 48;
						}
						> a {
							@include font_size_l;
							display: inline-block;
							margin: 0 0 32px;
							color: $color__white;
						}
						.primary_navigation_top_level_entries {
							font-family: $font__univers;
							margin: 0 -24px;
							> li {
								width: 33.33333%;
								display: inline-block;
								vertical-align: top;
								margin: 0 0 32px;
								a {
									border-right: 1px solid $color__white;
									padding: 0 24px 12px;
									display: block;
								}
								&:nth-child(3n) {
									> a {
										border-right: 0 none;
									}
								}
								.primary_navigation_child_entries {
									@include font_size_xs;
									font-weight: 400;
									a {
										padding: 0 24px 4px;
									}
								}
							}
						}
					}
					a {
						color: $color__white;
						text-decoration: none;
						&:hover {
							text-decoration: underline;
						}
					}
				}
				&:hover {
					color: $color__mid_green;
					cursor: pointer;
				}
				&.active {
					> span {
						color: $color__mid_green;
					}
					background-image: url($images_dir + 'caret_up_green.svg');
					.primary_navigation_section {
						// Nothing
					}
				}
			}
		}
		#secondary_navigation {
			padding: 16px 0 0;
			@include font_size_xs;
			a {
				margin: 0 0 0 8px;
	            color: $color__white;
	            text-decoration: none;
	            &:hover {
	                text-decoration: underline;
	            }
			}
			.vertical {
				border-right: 1px solid $color__light_blue;
				display: inline-block;
				height: 32px;
				vertical-align: middle;
				margin: 0 0 0 8px;
			}
			.social {
				display: inline-block;
				a {
					margin: 0 0 0 16px;
				}
			}
		}
		@include breakpoint(large down){
			min-height: 128px;
			#primary_navigation {
				> li {
					.primary_navigation_section {
						> li {
							.primary_navigation_top_level_entries {
								> li {
									width: 50%;
									a {
										// border-right: 1px solid $color__white;
									}
									&:nth-child(3n) {
										> a {
											border-right: 1px solid $color__light_blue;
										}
									}
									&:nth-child(2n) {
										> a {
											border-right: 0 none;
										}
									}
								}
							}
						}
					}
				}
			}
			#secondary_navigation {
				margin: 16px 0 0;
				border-top: 1px solid $color__light_blue;
				a {
					margin: 0 8px 0 0;
				}
				.vertical {
					margin: 0 8px 0 0;
				}
				.social {
					a {
						margin: 0 16px 0 0;
					}
				}
			}
		}
		@include breakpoint(small down){
			min-height: 0;
			#primary_navigation {
				> li {
					margin: 0;
					padding: 16px 0 12px;
					display: block;
					border-top: 1px solid $color__light_blue;
					border-right: 0 none;
					background-position: right 0 top 27px;
					.primary_navigation_section {
						position: relative;
						> li {
							border: 0 none;
							margin: 0;
							padding: 12px 0 0;
							> a {
								font-family: $font__univers;
								@include font_size_s;
								margin: 0 0 16px;
							}
							.primary_navigation_top_level_entries {
								> li {
									padding: 0 0 8px;
									display: block;
									width: auto;
									margin: 0;
									> a {
										@include font_size_s;
									}
								}
							}
						}
					}
				}
			}
			#secondary_navigation {
				margin: 0 0 16px;
				border-bottom: 1px solid $color__light_blue;
				.vertical {
					height: 20px;
				}
				.social {
					display: block;
					margin: 16px 0;
				}
			}
		}
	}
	#page_header {
        width: 100%;
		margin: -1px 0 0;
		background-color: transparent;
		padding: 0 0 64px;
		body.home & {
			display: none;
		}
		body.error_page &,
		body.search &,
		body.shop & {
			background-image: none;
			padding: 0;
		}
		body.section_get_gardening_home & {
			padding: 0;
		}
		#page_header_inner {
			padding: 14px 0 32px;
			@include breakpoint(medium){
				padding: 28px 0 64px;
			}
			background-color: $color__mid_green;
			body.get_involved_home &,
			body.section_get_involved &,
			body.donate & {
				background-color: $color__yellow;
			}
			body.get_training_home &,
			body.section_get_training & {
				background-color: $color__mid_blue;
			}
			body.error_page &,
			body.search &,
			body.shop & {
				background-color: transparent;
				padding: 32px 0 0;
			}
			body.section_get_gardening_home & {
				padding-bottom: 0;
			}
			#breadcrumbs {
				list-style-type: none;
				margin: 0;
				@include font_size_xs;
				li {
					display: inline-block;
					background-image: url($images_dir + 'caret_right_dark_green.svg');
					background-position: right 4px top 40%;
					padding: 0 20px 0 0;
					&:last-of-type {
						background-image: none;
					}
				}
			}
			#page_title {
				margin: 32px 0 48px;
				text-align: center;
				body.error_page & {
					margin: 60px auto 48px;
					max-width: 816px;
				}
				body.checkout &,
				body.product & {
					text-align: left;
					margin-bottom: 8px;
				}
				body.search & {
					margin-bottom: 24px;
				}
				body.section_get_gardening_home & {
					margin-bottom: 24px;
				}
			}
			#post_date {
				text-align: center;
				@include font_size_xs;
				margin: -24px 0 36px;
			}
		}
    }
}

.illustrations {
	z-index: -1;
	position: relative;
	height: 193px;
	@include breakpoint(small down){
		height: 144px;
	}
	body.donate #header & {
		height: 16px;	
	}
	body.error_page #header &,
	body.search #header &,
	body.shop #header &,
	body.section_get_gardening_home & {
		height: 0;
	}
	.illustration,
	.rough_strip {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background-repeat: repeat-x;
	}
	.illustration {
		background-size: 1024px 193px;
		background-position: left 50% top 0;
		background-image: url($images_dir + 'header_illustrations/leaves_illustration.png');
		@include breakpoint(small down){
			background-size: auto 144px !important;
		}
		body.get_involved_home &,
		body.section_get_involved &,
		body.donate & {
			background-image: url($images_dir + 'header_illustrations/flowers_illustration.png');
			background-size: 512px 192px;
		}
		body.get_training_home &,
		body.section_get_training & {
			background-image: url($images_dir + 'header_illustrations/tools_illustration.png');
			background-size: 512px 192px;
		}
		#footer & {
			transform: rotate(180deg);
		}
		body.error_page #header &,
		body.donate #header &,
		body.search #header &,
		body.shop #header & {
			background-image: none;
		}
	}
	.rough_strip {
		background-size: 1024px 16px;
		background-position: left 50% top 0;
		background-image: url($images_dir + 'header_illustrations/rough_strip_mid_green.png');
		body.get_involved_home &,
		body.section_get_involved &,
		body.search #header &,
		body.donate & {
			background-image: url($images_dir + 'header_illustrations/rough_strip_yellow.png');
		}
		body.get_training_home &,
		body.section_get_training & {
			background-image: url($images_dir + 'header_illustrations/rough_strip_mid_blue.png');
		}
		#footer & {
			transform: rotate(180deg);
			background-image: url($images_dir + 'header_illustrations/rough_strip_grey.png');
		}
		body.error_page #header &,
		body.shop #header & {
			background-image: none;
		}
	}
}
