#shop_menu {
	background-color: $color__light_grey;
	padding: 18px 0;
	@include font_size_xs;
	a {
		text-decoration: none;
		&.shop_home_link {
			padding: 0 16px 0 0;
		}
		&.product_category {
			border-left: 1px solid $color__dark_blue;
			padding: 8px 16px;
			text-decoration: none;
			&:hover {
				border-color: $color__dark_blue;
			}
		}
		&.basket_link {
			padding: 8px 46px 8px 0;
			background-image: url($images_dir + 'shop/basket.svg');
			background-position: top 2px right 0;
		}
	}
}

.product_thumbs {
	margin: 32px 0 0;
	.product_thumb {
		display: block;
		margin: 0 0 40px;
		text-decoration: none;
		.inner {
			display: block;
			border: 1px solid $color__dark_green;
			border-top: 0 none;
			padding: 16px 24px;
			transition: .25s border-color;
			min-height: 128px;
			.title {
				font-weight: 600;
				display: block;
				text-decoration: underline;
				margin: 0 0 8px;
			}
			.excerpt {
				display: block;
				color: $color__dark_grey;
				@include font_size_xs;
			}
			.price {
				margin: 16px 0 8px;
				min-height: 64px;
			}
			.add_to_basket_form {
				[type=submit] {
					margin: 0 0 16px;
					&:active {
						margin: 4px 0 12px;
					}
				}
			}
		}
		&:hover {
			.inner {
				border-color: $color__dark_grey;
			}
		}
	}
}

#promoted_categories {
	.inner {
		padding-bottom: 16px !important;
	}
	&.padding_bottom {
		padding-bottom: 120-32px !important;
	}
	.category_thumb {
		z-index: 100000;
	}
}

.category_thumb {
	display: block;
	margin: 0 0 40px;
	text-decoration: none;
	.image_wrapper {
		background-color: $color__light_grey;
	}
	.thumb_inner {
		display: block;
		background-color: $color__white;
		padding: 16px 24px;
		min-height: 224px;
		.title {
			font-weight: 600;
			display: block;
			text-decoration: underline;
			margin: 0 0 8px;
		}
		.excerpt {
			display: block;
			color: $color__dark_grey;
			@include font_size_xs;
		}
	}
}

.qty_buttons {
	input {
		display: inline-block;
		width: 48px;
		margin: 0 2px;
		text-align: center;
		color: $color__mid_grey;
		border-color: $color__mid_grey;
	}
}

.price {
	font-weight: 600;
	margin: 0 0 8px;
}

body.product {
	img {
		margin: 16px 0 32px;
	}
	.carousel {
		margin-top: 16px;
		margin-bottom: 60px !important;
		img {
			margin: 0;
		}
	}
}

.discount {
	color: $color__red;
	font-weight: 600;
	&.light {
		font-weight: 500;
	}
}

.donation {
	font-weight: 600;
}

#basket {
	.basket_item {
		hr {
			margin: 48px 0;
		}
		h2 {
			color: $color__dark_blue;
			margin: 0 0 8px;
		}
		.qty_buttons {
			input,
			.button {
				margin-bottom: 8px;
			}
		}
		.update_basket {
			margin-bottom: 8px;
		}
		.remove_item_from_basket {
			color: $color__red;
			@include font_size_xs;
		}
	}
	.field.promo_code {

	}
	#promo_code {
		max-width: 624px;
		padding: 0 0 32px;
		.field {
			@include font_size_xs;
			input {
				margin: 0 0 12px 0;
			}
			#promo_code_field {
				width: 240px;
				display: inline-block;
				margin: 0 8px 12px 0;
			}
		}
	}
}

#order_summary {
	border-top: 1px solid $color__mid_green;
	padding: 24px 0 0;
	max-width: 624px;
	hr {
		border-color: $color__mid_grey;
	}
}

#checkout {
	margin: 48px 0 0;
	hr {
		border-color: $color__mid_green;
	}
	h3 {
		font-family: $font__univers;
		font-weight: 500;
		margin: 0 0 24px;
		@include font_size_ml;
	}
	input,
	select {
		max-width: none;
	}
	#checkout_progress {
		position: relative;
		height: 16px;
		background-color: $color__light_grey;
		margin: 0 0 32px;
		#checkout_progress_bar {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			background-color: $color__dark_green;
		}
	}
	.shipping_address_same_as_billing_wrapper {
		margin: 0 0 24px;
	}
	.shippingAddress {
		margin: 0 0 24px;
	}
	#donate_widget {
		margin-top: 0;
		.donate_box {
			padding: 16px 0 0;
		}
	}
	#keep_in_touch {
		padding: 0 0 24px;
	}
}

#order {
	#order_number {
		font-family: $font__univers;
		font-weight: 500;
		margin: 0 0 16px;
		@include font_size_ml;
	}
	.postage,
	.donation {
		font-weight: 600;
	}
	.item {
		.image {
			margin: 0;
		}
		h2 {
			@include font_size_m;
			color: $color__dark_blue;
			margin: 0 0 8px;
		}
		p {
			margin: 0 0 8px;
		}
		.price {
			margin: 0;
		}
	}
	#order_total {
		margin: 0 0 24px;
	}
	hr {
		margin: 24px 0;
	}
	#order_summary {
		padding: 0;
		border-top: 0 none;
		max-width: none;
	}
}

body.order {
	#page {
		.block {
			&_nav_boxes_with_images {
				h2 {
					border-top: 0 none;
				}
			}
		}
	}
}

#attendees {
	h4 {
		font-weight: 600;
	}
	.attendee {
		margin: 0 0 24px;
		padding: 0 0 24px;
		border-bottom: 1px solid $color__mid_green;
		h5 {
			color: $color__dark_green;
			font-weight: 600;
		}
		.attendee_fields {
			margin: 24px 0 0;
		}
	}
}
