.pop-up-banner {
  position: fixed;
  z-index: 10000000;
  right: 0;
  bottom: 0;
  display: none; // hide as default, so it doesn't show up while page loading
  padding-right: 32px;

  &__scroll {
    overflow: scroll;
    height: 100vh; // this keep the banner scrollerable when height is bigger than the screen
  }

  &__center-position {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    min-height: 100%; //this keeps the element vertically centred 
  }

  &__banner {
    position: relative;
    max-width: 432px;
    padding: 24px 24px 32px;
    background-color: $color__yellow;
    border-radius: 8px;
    pointer-events: all;
    margin-bottom: 32px;
  }

  &__image {
    margin-bottom: 24px;
    display: none;
  }

  &__heading {
    margin-bottom: 8px;
  }

  &__button {
    margin: 0;

    &:active {
      margin: 4px 0 -4px;
    }
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: $color__dark_blue;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.5 0.5L0.5 2.5L21.5 23.5L23.5 21.5L2.5 0.5Z' fill='%23FCFAF8'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.5 21.5L2.5 23.5L23.5 2.5L21.5 0.5L0.5 21.5Z' fill='%23FCFAF8'/%3E%3C/svg%3E%0A");
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

    &:hover {
      background-color: lighten($color__dark_blue, 10%);
    }
  }

  @include breakpoint(medium){
    &__banner {
      padding: 32px 32px 40px;
    }

    &__image {
      display: block;
    }

    &__close {
      top: 16px;
      right: 16px;
    }
  }
}