.block-quote {
  padding-left: 12px;
  padding-right: 12px;

  &__asset {
    margin-bottom: 16px;
  }

  &__content {
    color: $color__dark_green;
  }

  &__quote {
    @include font_size_sm;
  }

  &__source {
    @include font_size_xs;
  }

  .left_quotes {
    vertical-align: top;
    display: inline-block;
    width: 42px;
    height: 28px;
    background-image: url($images_dir + 'quote_left.png');
    background-size: auto 24px;
    background-position: left 0 bottom 0;
  }

  .right_quotes {
      vertical-align: top;
      display: inline-block;
      width: 42px;
      height: 28px;
      background-image: url($images_dir + 'quote_right.png');
      background-size: auto 24px;
      background-position: right 0 bottom 0;
  }

  @include breakpoint(medium){
    display: flex;
    align-items: center;

    &__asset {
      flex: 0 0 32.5%;
      margin-right: 4%;
      margin-bottom: 0;
    }

    &__content {
      margin-right: 4%;
    }
  }

  // @include breakpoint(large){
  //   padding-left: 4%;
  //   padding-right: 4%;
  // }

  @include breakpoint(extralarge){
    padding-left: 0;
    padding-right: 0;
  }
}
