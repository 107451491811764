input,
input[type=color],
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
input[type=file] {
	height: 40px;
	border: 1px solid $color__dark_grey;
	border-radius: 4px;
	@include font_size_xs;
	padding: 12px 8px 12px;
	max-width: 480px;
	margin: 0 0 8px;
	background-color: $color__white;
	&:focus {
		background-color: $color__white;
	}
}

textarea {
	min-height: 120px;
	border: 1px solid $color__dark_grey;
	border-radius: 4px;
	@include font_size_xs;
	padding: 12px 8px 12px;
	max-width: 480px;
	margin: 0 0 8px;
	background-color: $color__white;
	&:focus {
		background-color: $color__white;
	}
}

label {
	display: block;
	margin: 0 0 6px;
	font-weight: 700;
}

::placeholder {
    color: lighten($color__dark_grey, 20%) !important;
}
::-webkit-input-placeholder {
    color: lighten($color__dark_grey, 20%) !important;
}
:-moz-placeholder {
    color: lighten($color__dark_grey, 20%) !important;
}
::-moz-placeholder {
    color: lighten($color__dark_grey, 20%) !important;
}
:-ms-input-placeholder {
    color: lighten($color__dark_grey, 20%) !important;
}

button,
.button,
[type=submit] {
	border: 0 none;
	display: inline-block;
	background-color: $color__dark_blue;
	color: $color__white !important;
	padding: 8px 24px;
	margin: 0 0 24px;
	box-shadow: 0 4px 0 0 $color__dark_grey;
	font-family: inherit;
	vertical-align: top;
	@include font_size_s;
	font-weight: 400;
	text-decoration: none !important;
	cursor: pointer;
	height: 42px;
	border-radius: 4px;
	transition: .25s background-color;
	@include breakpoint(medium){
		height: 48px;
		padding: 10px 24px;
	}
	&:hover {
		background-color: lighten($color__dark_blue, 10%);
		box-shadow: 0 4px 0 0 lighten($color__dark_grey, 10%);
	}
	&:active {
		box-shadow: none !important;
		margin: 4px 0 20px;
	}
	&.white {
		color: $color__dark_blue !important;
		background-color: $color__white;
		box-shadow: 0 4px 0 0 $color__light_blue;
		&:hover {
			background-color: darken($color__white, 10%);
			box-shadow: 0 4px 0 0 darken($color__light_blue, 10%);
		}
	}
	&.yellow {
		color: $color__dark_blue !important;
		background-color: $color__yellow;
		box-shadow: 0 4px 0 0 $color__orange;
		&:hover {
			background-color: lighten($color__yellow, 20%);
			box-shadow: 0 4px 0 0 lighten($color__orange, 10%);
		}
	}
	&.grey {
		color: $color__dark_blue !important;
		background-color: $color__light_grey;
		box-shadow: 0 4px 0 0 $color__mid_grey;
		&:hover {
			background-color: darken($color__light_grey, 10%);
			box-shadow: 0 4px 0 0 darken($color__mid_grey, 10%);
		}
	}
	&.plus_one,
	&.minus_one,
	&.update_basket {
		background-color: $color__white;
		border: 1px solid $color__dark_blue;
		box-shadow: none !important;
		height: 40px;
		width: 40px;
		padding: 0;
		text-align: center;
		font-weight: 600;
		background-position: center;
		&:hover {
			background-color: lighten($color__dark_blue, 90%);
		}
		&:active {
			margin-top: 0;
		}
	}
	&.update_basket {
		width: auto;
		font-weight: 500;
		@include font_size_xs;
		padding-left: 8px;
		padding-right: 8px;
		color: $color__dark_blue !important;
	}
	&.plus_one {
		background-image: url($images_dir + 'shop/plus.svg');
	}
	&.minus_one {
		background-image: url($images_dir + 'shop/minus.svg');
	}
	&.remove_item_from_basket_btn {
		background-color: $color__red;
		box-shadow: none !important;
		height: 40px;
		width: 40px;
		margin: 0;
		padding: 0;
		text-align: center;
		background-image: url($images_dir + 'shop/cross.svg');
		background-position: center;
		vertical-align: middle;
		&:hover {
			background-color: lighten($color__red, 10%);
		}
	}
	&:disabled {
		color: $color__mid_grey !important;
		box-shadow: 0 4px 0 0 lighten($color__mid_grey, 40%) !important;
		background-color: $color__light_grey !important;
		cursor: not-allowed;
	}
}

select {
	height: 40px;
	border: 1px solid $color__dark_grey;
	border-radius: 4px;
	@include font_size_xs;
	padding: 0 32px 0 8px;
	margin: 0 0 8px;
    background-image: url($images_dir + 'caret_down_green.svg');
	background-size: 20px 12px;
    background-position: right -24px top 50%;
	width: auto;
	max-width: 100%;
	min-width: 296px;
	background-color: $color__white;
	&:active {
		background-color: $color__white;
	}
	@include breakpoint(medium){
		max-width: 480px;
	}
}

label.input_checkbox {
	display: block;
	margin: 0 0 8px;
    position: relative;
    padding: 0 0 0 36px;
	font-weight: 400;
    input {
        position: absolute;
        opacity: 0;
        &:checked + .checkbox {
			background-color: $color__dark_blue;
			border: 1px solid $color__dark_blue;
            background-image: url($images_dir + 'tick.svg');
            background-position: center;
        }
    }
    .checkbox {
        position: absolute;
		top: 4px;
        left: 0;
        width: 21px;
        height: 21px;
        display: inline-block;
        border: 1px solid $color__dark_grey;
        transition: .25s background-color;
		border-radius: 3px;
		background-color: $color__white;
    }
}

label.input_radio {
    font-weight: 400;
    position: relative;
	padding: 0 0 0 36px;
    input {
        position: absolute;
        opacity: 0;
        &:checked + .radio {
            border: 1px solid $color__dark_blue;
            span {
                background-color: $color__dark_blue;
                width: 13px;
                height: 13px;
                border-radius: 7px;
                display: inline-block;
                position: absolute;
                margin: 3px 0 0 3px;
            }
        }
    }
    .radio {
        position: absolute;
        left: 0;
        top: 4px;
		width: 21px;
        height: 21px;
        display: inline-block;
        border: 1px solid $color__dark_grey;
        border-radius: 16px;
        span {
            transition: .25s background-color;
        }
    }
}

form {
	h2 {
		margin: 0 0 24px;
	}
	h3 {
		@include font_size_ml;
		margin: 0 0 24px;
		label {
			margin: 0;
			font-weight: 400;
		}
	}
	hr {
		border-color: $color__mid_green;
		margin: 24px 0;
	}
}

form.show_errors {
	.has_errors {
		color: #FF0000 !important;
		border-color: #FF0000 !important;
		* {
			color: #FF0000 !important;
			border-color: #FF0000 !important;
		}
		.errors {
			display: block;
			margin: 0 0 24px;
		}
	}
}

.errors.show_errors {
	color: #FF0000 !important;
}

.postcode_error {
	color: #FF0000;
	@include font_size_xs;
}

.donate_form_errors {
	color: #D00;
	border: 1px solid #D00;
	background-color: #FEE;
	padding: 12px;
	margin: 8px 0 32px;
	font-weight: 600;
	display: none;
	&.show_errors {
		display: block;
	}
}

.field,
.field_group {
	&.flex {
		@include breakpoint(medium){
			display: flex;
			.field {
				flex-shrink: 1;
			}
		}
	}
	.errors {
        display: none;
    }
}

.form_padding {
	margin: 0 0 48px;
}

// Freeform

#page {
	.ff-errors {
		color: $color__red;
		li {
			padding: 0;
			&:before {
				background-image: none;
			}
		}
	}
	.ff-form-errors {
		border-radius: 0;
		border: 0 none;
		background-color: lighten($color__red, 90%);
		margin: 0 0 24px;
		p {
			color: $color__red;
		}
	}
	.ff-form-success {
		color: $color__mid_green;
	}
}

// Stripe

#stripe_card_element {
	height: 40px;
	border: 1px solid $color__dark_grey;
	border-radius: 4px;
	padding: 8px 0 8px 8px;
	max-width: 480px;
	margin: 0 0 8px;
}
