.modal_wrapper {
	display: block;
	opacity: 0;
	pointer-events: none;
	transition: .25s opacity;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.4);
	text-align: center;
	z-index: 10000;
	padding: 24px;
	.modal_spacer {
		height: 100%;
		display: inline-block;
		width: 0;
		vertical-align: middle;
	}
	.modal {
		width: 1200px;
		display: inline-block;
		vertical-align: middle;
		background-color: $color__white;
		min-height: 100px;
		padding: 48px;
		text-align: left;
		h3 {
			font-weight: 600;
		}
		a,
		span,
		.button {
			margin: 0 16px 0 0;
			padding: 16px 24px 14px 0;
			display: inline;
			vertical-align: top;
			line-height: 56px;
		}
		.button {
			padding: 16px 24px 14px;
			box-shadow: none;
		}
		.close_btn {
			display: inline-block;
			width: 40px;
			height: 40px;
			background-image: url($images_dir + 'close_modal.svg');
			background-size: contain;
			margin: 0 0 8px;
		}
		.close {
			color: $color__dark_blue;
			text-decoration: underline;
			&:hover {
				color: $color__black;
				cursor: pointer;
			}
		}
		.modal_inner {
			@include breakpoint(large){
				display: flex;
				& > div {
					flex: 1;
				}
				.image {
					flex: 0 0 336px;
					width: 336px;
					margin: 0 48px 0 0;
				}
			}
		}
		@include breakpoint(medium down){
			a,
			span {
				display: block;
				line-height: 1em;
				padding: 0;
				margin: 24px 0 0;
			}
			.image {
				margin: 0 0 24px;
			}
			.close {
				margin: 0;
			}
		}
		@include breakpoint(1248px down){
			width: 100%;
		}
	}
	&.visible {
		opacity: 1;
		pointer-events: all;
	}
	&.added_to_basket_modal {
		.modal_spacer {
			display: none;
		}
		.modal {
			margin: 24px 0 0;
			vertical-align: top;
		}
	}
	&.course_info_modal {
		.modal {
			width: 624px;
			margin: 24px 0 0;
			vertical-align: top;
			.modal_inner {
				display: block;
			}
			.modal_excerpt {
				margin: 0 0 16px;
			}
		}
	}
}
