.donate_page {
  header#header #page_header {
    padding-bottom: 0;
  }
  
  .beacon-background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 0;
    
    .bg-yellow {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 400px;
      background-color: $color__yellow;
    }

    .illustrations {
      top: 400px;
      z-index: auto;
    }
  }

  .beacon-container {
    position: relative;

    .page-title {
      position: absolute;
      left: -9999em;
      top: auto;
      width: 1px;
      height: 1px;
      overflow: hidden;
    }
  }
}