// Paths
$images_dir: "../img/";

// Colours
$color__black: #000000;
$color__white: #fcfaf8;
$color__dark_grey: #283228;
$color__mid_grey: lighten($color__dark_grey, 30%);
$color__light_grey: lighten($color__dark_grey, 85%);
$color__red: #e42313;
$color__dark_green: #006d00;
$color__mid_green: #81bb27;
$color__light_green: #c5d300;
$color__yellow: #fbba00;
$color__orange: #e27e00;
$color__dark_blue: #2e008b;
$color__mid_blue: #2bb8c9;
$color__light_blue: #a4bbe2;
$color__terracotta: #a14e4c;
$color__teal: #9DE1EA;

// Fonts
$font__sanchez: 'Sanchez', serif;
$font__univers: 'UniversLTPro', sans-serif;
