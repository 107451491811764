.pagination {
    border-top: 1px solid $color__mid_green;
    margin-top: 48px;
    padding-top: 24px;
    text-align: right;
    .prev, .next, .numbers, .number {
        display: inline-block;
    }
    .prev, .next, .number {
        text-decoration: none;
        padding: 4px 16px;
        border-radius: 4px;
    }
    a {
        color: $color__dark_green;
        border: 1px solid $color__mid_green;
        &:hover {
            color: $color__dark_blue;
            background-color: $color__mid_green;
        }
    }
    span {
        color: $color__mid_grey;
        border: 1px solid $color__white;
    }
    .prev, .next {
        margin-left: 6px;
        margin-right: 6px;
        &:first-child {
            margin-left: 0;
            &:before {
                content: '\2039';
                margin-right: 8px;
            }
        }
        &:last-child {
            margin-right: 0;
            &:after {
                content: '\203A';
                margin-left: 8px;
            }
        }
        &:before, &:after {
            font-size: 2rem;
            vertical-align: -2px;
        }
        &:hover {
            background-color: $color__white;
        }
        &.disabled {
            display: none;
        }
    }
    span, .prev, .next {
        padding: 4px 0;
        border: 1px solid $color__white;
    }
    .numbers, .number {
        margin-left: 2px;
        margin-right: 2px;
    } 
    .number {
        &__current {
            color: $color__dark_blue;
            border: 1px solid $color__dark_blue;
        }
    }
}