.icon {
	width: 112px;
	height: 112px;
	display: block;
	margin: 0 0 16px;
	background-position: center;
	background-size: contain;
	&.large {
		width: 216px;
		height: 216px;
	}
	&_apple {
		background-image: url($images_dir + 'icons/apple.png');
	}
	&_envelope {
		background-image: url($images_dir + 'icons/envelope.png');
	}
	&_flower {
		background-image: url($images_dir + 'icons/flower.png');
	}
	&_hands {
		background-image: url($images_dir + 'icons/hands.png');
	}
	&_map {
		background-image: url($images_dir + 'icons/map.png');
	}
	&_plant_in_hand {
		background-image: url($images_dir + 'icons/plant_in_hand.png');
	}
	&_flower_in_hand {
		background-image: url($images_dir + 'icons/flower_in_hand.png');
	}
	&_trowel {
		background-image: url($images_dir + 'icons/trowel.png');
	}

}

.social_icon {
	width: 24px;
	height: 24px;
	display: inline-block;
	vertical-align: middle;
	background-position: center;
	background-size: contain;
	margin: 0 0 0 16px;
	&:hover {
		opacity: 0.8;
	}
	&.instagram {
		background-image: url($images_dir + 'social/instagram.svg');
	}
	&.twitter {
		background-image: url($images_dir + 'social/twitter.svg');
	}
	&.facebook {
		background-image: url($images_dir + 'social/facebook.svg');
	}
	&.youtube {
		background-image: url($images_dir + 'social/youtube.svg');
	}
}
