#home_hero_image {
	padding-top: 40%;
	position: relative;
	background-size: cover;
	background-position: center;
	.rough_strip {
		position: absolute;
		height: 16px;
		width: 100%;
		background-size: 1024px 16px;
		background-repeat: repeat-x;
		background-position: center;
		bottom: 0;
		transform: rotate(180deg);
		background-image: url($images_dir + 'header_illustrations/rough_strip_white.png');
	}
}

#home_title_and_standfirst {
	margin: -96px 0 0 !important;
	text-align: center;
	h1 {
		@include font_size_xxl;
		margin: 16px 0 24px;
	}
	p {
		@include font_size_ml;
		max-width: 720px;
		margin: 0 auto 24px;
	}
	@include breakpoint(medium down){
		margin: -32px 0 0 !important;
	}
}

body.home {
	.rough_block {
		h2 {
			color: $color__dark_grey !important;
		}
		&.rough_block_full_width {
			.padded_inner {
				margin: 0 48px;
				@include breakpoint(1350px down){
					margin: 0;
				}
			}
			.illustration_top,
			.illustration_bottom {
				position: absolute;
				width: 100%;
				height: 193px;
				background-repeat: repeat-x;
				background-position: left 50% top 0;
				z-index: -1;
			}
			.illustration_top {
				margin: 0 0 -16px;
				bottom: 100%;
				background-image: url($images_dir + 'header_illustrations/leaves_illustration.png');
				background-size: 1024px 193px;
				transform: rotate(180deg);
			}
			.illustration_bottom {
				margin: -16px 0 0;
				top: 100%;
				background-image: url($images_dir + 'header_illustrations/tools_illustration.png');
				background-size: 512px 192px;
			}
		}
	}
	#donate_widget {
		margin: 0 0 32px;
		.donate_box {
			border-top: 1px solid $color__orange;
			border-bottom: 1px solid $color__orange;
			padding: 16px 0 8px;
			@include breakpoint(medium){
				margin: 40px 48px;
			}
		}
	}
}
