@media print {
    * {
        -webkit-print-color-adjust: exact !important; // Chrome, Safari
        print-color-adjust: exact !important; // Firefox
    }
    // Hide links (which webkit likes to put in the page)
    a[href]:after {
        content: none !important;
    }
}
