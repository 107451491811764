#page {
  /* Arrows */
  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 48px;
    width: 48px;
    border-radius: 4px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background-color: $color__dark_blue;
    box-shadow: none;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    z-index: 10;
    &:hover, &:focus {
      outline: none;
      background-color: lighten($color__dark_blue, 10%);
      box-shadow: none;
      color: transparent;
    }
    background-position: center;
  }
  .slick-prev {
    left: -12px;
    background-image: url(../img/caret_left_green.svg);
  }
  .slick-next {
    right: -12px;
    background-image: url(../img/caret_right_green.svg);
  }
  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
      &:before {
        content: none !important;
      }
      position: relative;
      display: inline-block;
      height: 16px;
      width: 16px;
      margin: 0 4px;
      padding: 0;
      cursor: pointer;
      button {
        border: 0;
        background: $color__light_green;
        box-shadow: none;
        display: block;
        height: inherit;
        width: inherit;
        border-radius: 50%;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent !important;
        padding: 5px;
        cursor: pointer;
        &:hover, &:focus {
          outline: none;
          background-color: $color__dark_blue;
        }
      }
      &.slick-active button {
        background-color: $color__dark_blue;
      }
    }
  }
}
