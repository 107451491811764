body.search {
	h2 {
		margin-top: 32px;
	}
	#search_results_load_more_products,
	#search_results_load_more_entries {
		margin-top: 32px;
		&:active {
			margin-top: 36px;
		}
	}
	h3 {
		@include font_size_ml;
	}
	.query_text {
		text-align: center;
		margin: 0 0 48px;
	}
	.search_result_item {
		border-top: 1px solid $color__mid_green;
		border-bottom: 1px solid $color__mid_green;
		margin: 0 0 -1px;
		padding: 48px 0 24px;
		h2 {
			margin: 0 0 16px;
			a {
				text-decoration: none;
				font-weight: 700;
			}
		}
		p {
			margin: 0 0 16px;
		}
		a {
			font-weight: 600;
		}
		.image_wrapper {
			background-color: $color__light_grey;
			padding-top: 66.666%;
			position: relative;
			overflow: hidden;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}
			margin: 0 0 24px;
		}
	}
}
