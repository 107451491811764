// Add percentage of white to a color
@function lighten($color, $percent){
    @return mix(white, $color, $percent);
}

// Add percentage of dark to a color
@function darken($color, $percent){
    @return mix(black, $color, $percent);
}

@mixin font_size_xs {
    font-size: 16px;
    line-height: 24px;
    @include breakpoint(medium){
        font-size: 18px;
        line-height: 24px;
    }
}

@mixin font_size_xs_alt {
    font-size: 16px;
    line-height: 26px;
    @include breakpoint(medium){
        font-size: 18px;
        line-height: 28px;
    }
}

@mixin font_size_s {
    font-size: 19px;
    line-height: 28px;
    @include breakpoint(medium){
        font-size: 20px;
        line-height: 32px;
    }
}

@mixin font_size_sm { // TODO ?
    font-size: 21px;
    line-height: 32px;
    @include breakpoint(medium){
        font-size: 21px;
        line-height: 32px;
    }
}

@mixin font_size_m {
    font-size: 21px;
    line-height: 32px;
    @include breakpoint(medium){
        font-size: 24px;
        line-height: 36px;
    }
}

@mixin font_size_ml {
    font-size: 22px;
    line-height: 32px;
    @include breakpoint(medium){
        font-size: 26px;
        line-height: 40px;
    }
}

@mixin font_size_l {
    font-size: 24px;
    line-height: 36px;
    @include breakpoint(medium){
        font-size: 28px;
        line-height: 40px;
    }
}

@mixin font_size_xl {
    font-size: 34px;
    line-height: 40px;
    @include breakpoint(medium){
        font-size: 48px;
        line-height: 56px;
    }
}

@mixin font_size_xxl {
    font-size: 34px;
    line-height: 40px;
    @include breakpoint(medium){
        font-size: 64px;
        line-height: 72px;
    }
}
