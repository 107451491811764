// Devmode (toggled in JS)
html.devmode {
	.grid-x {
		// outline: 1px solid rgba(0,0,0,0.2);
		background-color: rgba(0,0,0,0.1);
	}
	.cell {
		outline: 1px solid rgba(0,0,0,0.2);
		background-color: rgba(255,0,0,0.2);
	}
	.illustration {
		outline: 1px solid rgba(0,255,0,0.75);
	}
}
